/** @format */

import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../images/loader.gif";
import { useNavigate } from "react-router-dom";

import {
  InfoContainer,
  FormWrap,
  Form,
  Heading,
  SmallText,
  InfoP1,
  InfoP2,
  Label,
  Input,
  Textarea,
  ButtonEl,
} from "./infoFormEls";

const InfoForm = ({
  heading,
  infoP1,
  infoP2,
  for1,
  label1,
  type1,
  placeHolder1,
  for2,
  label2,
  smallText,
  for3,
  label3,
  type3,
  placeHolder3,
  for4,
  label4,
  type4,
  buttonType,
  formType,
}) => {
  const [itemName, setItemName] = useState(null);
  const [shortD, setShortD] = useState(null);
  const [location, setLocation] = useState(null);
  const [date, setDate] = useState(null);
  const [type, setType] = useState(formType);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState({});

  const apiURL = process.env.REACT_APP_API_URL;
  const jwebtoken = Cookies.get("_udjwt");

  const navigate = useNavigate();

  useEffect(() => {
    let itemDetails = Cookies.get("pageFrom");
    if (itemDetails) {
      itemDetails = JSON.parse(itemDetails);
      setItemDetails(itemDetails);
      setItemName(itemDetails.itemName);
      setLocation(itemDetails.location);
      setDate(itemDetails.date);
      setShortD(itemDetails.shortD);
    
      Cookies.remove("pageFrom")

      if (itemDetails.type !== formType) {
        setItemName(null);
        setLocation(null);
        setDate(null);
        setShortD(null);
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setToken(jwebtoken);
    setLoading(true);
    if (!jwebtoken) {
      // Save the item details (name, location, date, description)

      Cookies.set(
        "pageFrom",
        JSON.stringify({
          path: "/home",
          itemName,
          type,
          shortD,
          location,
          date,
        })
      );

      // Redirect them to login

      alert("Kindly login before you proceed.");
      navigate("/signin");
    }

    // eslint-disable-next-line no-lone-blocks
    {
      if (token) {
        const response = await axios({
          method: "post",
          url: `${apiURL}/reportitem`,
          data: {
            itemName: itemName,
            shortD: shortD,
            location: location,
            date: date,
            token: token,
            type: type,
          },
        });
        setLoading(false);
  
        if (response.data.message === "item reported Successfully") {
          setItemName("");
          setLocation("");
          setDate("");
          setShortD("");
          toast.success(response.data.message);

        } else {
          toast.error(response.data.message);
        }
      } else {
        setLoading(false);
      }
    }


  };

  return (
    <InfoContainer>
      <Heading>{heading}</Heading>
      <InfoP1>{infoP1}</InfoP1>
      <InfoP2>{infoP2}</InfoP2>
      <FormWrap>
        <Form action="#" onSubmit={handleSubmit}>
          <Label htmlFor={for1} name={itemName}>
            {label1}
          </Label>
          <Input
            name="nameOfItem"
            type={type1}
            placeholder={placeHolder1}
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            required
          />
          <Label htmlFor={for2}>
            {label2} <SmallText>{smallText}</SmallText>
          </Label>
          <Textarea
            rows={3}
            name="description"
            onChange={(e) => setShortD(e.target.value)}
            required
            value={shortD}
          ></Textarea>
          <Label htmlFor={for3}>{label3}</Label>
          <Input
            placeholder={placeHolder3}
            name = "location"
            type={type3}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
          <Label htmlFor={for4}>{label4}</Label>
          <Input
            type={type4}
            name = "date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <ButtonEl to="" type={buttonType}>
            {loading ? (
              <img src={loader} alt="" width={20} />
            ) : formType === "found" ? (
              "Look for Owner"
            ) : (
              "Help Find"
            )}
          </ButtonEl>
        </Form>
      </FormWrap>
      <ToastContainer />
    </InfoContainer>
  );
};

export default InfoForm;

import React, { useState} from "react";
import SideBar from "../components/sideBar";
import Navbar from "../components/navBar";
import { response } from "../response";


function Faqs() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };



  return (
    <div className="faqs">
      <SideBar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div>
        <h1
          style={{
            fontSize: "1.5rem",
            textAlign: "center",
            color: "#ffd300",
            fontWeight: "700",
            padding: "1rem",
            borderBottom: "1px solid black ",
          }}
        >
          Frequently Asked Questions
        </h1>
        {/* {isLoaded && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <img src={loader} width="80px" alt="" />
          </div>
        )} */}
      </div>

      {response.map((item) => (
        <div key={item.id} className ="summary">
          <details>
            <summary>{item.question}</summary>
            <p>{item.answer}</p>
          </details>
        </div>
      ))}
    </div>
  );
}

export default Faqs;

import React from "react";
import FullWidthTabs from "./tabs";
import AuthHeader from "./AuthHeader";
import ClaimForm from "./claimItem/ClaimForm";
import FindForm from "../pages/findForm";
import ReturnForm from "../pages/returnForm";

const Homebody = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AuthHeader />
      <FullWidthTabs />
    </div>
  );
};

export default Homebody;

import axios from "axios";

export const callApiMetrics = async ({
    action,
    email,
    origin
}) => {
    try {
        console.log({ origin })
        const regexMatcher = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let response
        if (email !== null && !email.match(regexMatcher)) {
            response = { success: false, message: 'enter valid email' }
        }
        await axios.put(`${process.env.REACT_APP_METRICS_API}`, { origin, action, email, email_validity: response ? 'false' : 'true' })

        return { success: response?.success || true, message: response?.message || 'Great, received!' }
    } catch (err) {
        console.log(err);
        return { message: err.message, success: false }
    }
};
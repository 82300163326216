import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll, ScrollLink } from "react-scroll";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./navEls";
import { callApiMetrics } from "../../utils/metrics";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  let navigate = useNavigate();

  function handleClick(path) {
    navigate(path);
  }
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo
              style={{ textDecoration: "none" }}
              to="/"
              onClick={toggleHome}
            >
              LOSTFINDER
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to=""
                  style={{ color: "#fff", textDecoration: "none" }}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  onClick={() => {
                    callApiMetrics({ action: 'to_home', email_validity: false, email: null, origin: window.location.href })
                    handleClick("/home")
                  }}
                >
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to=""
                  style={{ color: "#fff", textDecoration: "none" }}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  onClick={() => {
                    callApiMetrics({ action: 'to_terms', email_validity: false, email: null, origin: window.location.href })
                    handleClick("/terms")
                  }}

                >
                  Terms & Conditions
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to=""
                  style={{ color: "#fff", textDecoration: "none" }}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  onClick={() => {
                    callApiMetrics({ action: 'to_faqs', email_validity: false, email: null, origin: window.location.href })
                    handleClick("/faqs")
                  }}


                >
                  FAQs
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={() => {
                    callApiMetrics({ action: 'to_signup', email_validity: false, email: null, origin: window.location.href })
                    handleClick("/signup")
                  }}
                  style={{
                    color: "#fff", textDecoration: "none"
                  }}
                  to=""
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Sign Up
                </NavLinks>
              </NavItem>
            </NavMenu>

            <NavBtn>
              <NavBtnLink
                style={{ textDecoration: "none" }}
                to="/signin">Sign In</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;

import styled from "styled-components";
import { MdArrowDownward } from "react-icons/md";

export const DetailsCon = styled.div`
  min-height: 100vh;
  margin-top: 15px;

`;

export const Div = styled.div`
  font-size: 1em;
  text-align: center;
`;
export const DetailsIcon = styled.div`
  text-align: center;
`;
export const Icon = styled(MdArrowDownward)`
  position: relative;
  bottom: 10px;
  font-size: 2em;
`;

export const H3 = styled.h3`
  color: #fff;
  text-align: center;
  font-size: 1em;
  margin: 6px auto;
  background: #101010;
  width: fit-content;
  padding: 5px;

`;

export const Card = styled.div`
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center; 
  height: fit-content;
  justify-content: center;
  flex-wrap: wrap;
  gap: 35px;
  padding: 0 2rem;


  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;

export const CardItem = styled.div`
  background: #fff;
  width: 350px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
 /* height: 220px; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  z-index: 3;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 300px;
 
    padding: 0 10px;
    margin: 0 auto;
  }
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const H1 = styled.h1`
  font-size: 1.2rem;
  color: #ffd300;
  margin: 5px 0;
  font-weight: 700;

  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

export const CardP = styled.p`
  font-size: 0.8rem;
  text-align: center;
  margin-top: 3px;
  min-height: 50px;
  height: auto;
  margin-bottom: 50px;
`;
export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
`;

/** @format */

import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import loader from "../../images/loader.gif";

import {
  Container,
  FormWrap,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
} from "./FormEls";

const ResetPassword = ({ formH1, formLabel1, formType1, for1, buttonType }) => {
  const [userEmail, setUserEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {

    try{
    setLoading(true);
    e.preventDefault();
    const apiURL = process.env.REACT_APP_API_URL;
    const response = await axios.post(`${apiURL}/resetpassword`, {
      userEmail: userEmail,
    });
    const message = response.data.message;
    setMessage(message);

    if (response) {
      setLoading(false);
    }

    if (response.data.status === 200) {
      swal("Done", message, "success");
      setLoading(false);
    } else if (response.data.status === 400) {
      swal("Error", "Email cannot be empty.", "error");
      setLoading(false);
    } else {
      swal("Error", message, "error");
      setLoading(false);
    }
  }catch(error){
    console.log({error})
    swal("Error", "there was an accident", "error");
    setLoading(false);


  }
  };

  return (
    <Container>
      <FormWrap>
        <Form action="#">
          <FormH1>{formH1}</FormH1>
          <FormLabel htmlFor={for1}>{formLabel1}</FormLabel>
          <FormInput
            type={formType1}
            onChange={(e) => setUserEmail(e.target.value)}
            name="userEmail"
            required
          />
          <FormButton onClick={(e) => handleSubmit(e)} type={buttonType}>
            {loading ? (
              <img src={loader} alt="" width={20} />
            ) : (
              "Retrieve Password"
            )}
          </FormButton>
        </Form>
      </FormWrap>
    </Container>
  );
};

export default ResetPassword;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import loader from "../../images/loader.gif";

import {
  Container,
  FormWrap,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  Text,
  LinkEl,
} from "./FormEls";

const SignupPage = ({
  formH1,
  formLabel1,
  formType1,
  formLabel2,
  formType2,
  formLabel3,
  formType3,
  for1,
  for2,
  for3,
  buttonType,
  text,
  span,
}) => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    userEmail: "",
  });
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const handleChange = (e) => {
    const clone = { ...userData };
    clone[e.target.name] = e.target.value;
    setUserData(clone);
  };


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const apiURL = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.post(`${apiURL}/signup`, userData);
      console.log(response);

      if (response.data.status === 201) {
        navigate("/feedback");
      } 
      else {
        swal("Error", response.data.message, "error");
      }
      setLoading(false);
    } catch (error) {
      console.log({error})
      swal("Error", error.response.data.message, "error");
      setLoading(false);
      return false


    }

    // fetch(`${apiURL}/signup`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json"},
    //   body: userData

    // }).then(res => {return res})
    // .then(data => {console.log(data)})
  };

  return (
    <>
      <Container>
        <FormWrap>
          <Form action="#" onSubmit={(e) => handleSubmit(e)}>
            <FormH1>{formH1}</FormH1>
            <FormLabel htmlFor={for1}>{formLabel1}</FormLabel>
            <FormInput
              name="firstName"
              type={formType1}
              required
              onChange={(e) => handleChange(e)}
            />
            <FormLabel htmlFor={for2}>{formLabel2}</FormLabel>
            <FormInput
              name="lastName"
              type={formType2}
              required
              onChange={(e) => handleChange(e)}
            />
            <FormLabel htmlFor={for3}>{formLabel3}</FormLabel>
            <FormInput
              name="userEmail"
              type={formType3}
              required
              onChange={(e) => handleChange(e)}
            />
            <FormButton type={buttonType}>
        
              {loading ? <img src={loader} alt="" width={20} /> : "Sign Up"}
            </FormButton>
            <Text>
              {text}
              <LinkEl to="/signin">{span}</LinkEl>
            </Text>
          </Form>
        </FormWrap>
      </Container>
    </>
  );
};

export default SignupPage;

import React, { useState } from "react";
import Navbar from "../components/navBar";
import SideBar from "../components/sideBar";

function Terms() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="terms">
      <SideBar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />
      <div className="body">
        <h1>Terms and Conditions</h1>

        <h4>
          Lostfinder uses the information held about you in the following ways:
        </h4>
        <ul >
          <li>To provide you with the necessary service you required.</li>
          <li>
            To carry out our obligations arising from any contracts entered into
            between you and us and to provide you with the information and
            services that you request from us.
          </li>
          <li>
            To provide you with information about other products and services we
            just launched, or those of partners or parent bodies – in order to
            bring you the best services.
          </li>
          <li>To notify you about changes to our company or services.</li>
          <li>
            More so, LostFinder and her associates, partners are not liable to
            any damages incurred by the use of this platform and other services.
          </li>

          <li>We shall not be answerable to any charges or whatsoever.</li>
          <li>
            The use of this platform and other services implies you agree with
            these terms and conditons.
          </li>
        </ul>

        <div>
          <h1>Some Safety Tips</h1>
          <ol style={{ paddingTop: "8px"}}>
            <li>
              The use of this platform and other services implies you agree with
              these terms and conditons.
            </li>
            <li>Go with friends.</li>
            <li>You may request for identity cards before meeting.</li>
            <li>
              Call their phone number and run it over TrueCaller or similar apps
              to confirm names and other details.
            </li>
            <li>Do not get into their cars.</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default Terms;

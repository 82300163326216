import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthHeader from "../components/AuthHeader";
import ClaimForm from "../components/claimItem/ClaimForm";
import FindOwnerForm from "../components/claimItem/FindOwnerForm";
import FoundItems from "../components/detailsSec/foundItems";
import LostItems from "../components/detailsSec/lostItems";
import axios from "axios";
import swal from "sweetalert";

function SharedItem() {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const [nameOfItem, setNameOfItem] = useState(null);
  const [description, setDescription] = useState(null);
  const [type, setType] = useState(null);
  const [itemStatus, setItemStatus] = useState(null);

  const togglePopup = () => {
    setIsOpen(false);
  };

  const setItemDetails = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await axios.get(`${apiUrl}/itemid/${id}`);
    console.log({ sdf: response.data.item[0] });
    const name = response.data.item[0].name;
    const item_status = response.data.item[0].status;
    const description = response.data.item[0].description;
    setNameOfItem(name);
    setDescription(description);
    setType(response.data.item[0].type);
    setItemStatus(item_status);
  };

  if (itemStatus === "settled") {
    swal("Hmmm", "Item already settled", "success");
  }

  const clearAlert = () => {
    return window.location.replace("/home");
  };

  useEffect(() => {
    setItemDetails();
    setTimeout(clearAlert, 5000);
  }, []);

  return (
    <div style={{ backgroundColor: "#ffd300", minHeight: "100vh" }}>
      <AuthHeader />

      {type === "lost" && itemStatus !== "settled" && (
        <div>
          <LostItems />
          {isOpen && (
            <FindOwnerForm
              id={id}
              handleClose={togglePopup}
              name={nameOfItem}
            />
          )}
        </div>
      )}
{
  type === "lost" && itemStatus === "settled" && (
    <LostItems />
  )
}

      {type === "found" && itemStatus !== "settled" && (
        <div>
          <FoundItems />
          {isOpen && (
            <ClaimForm
              id={id}
              handleClose={togglePopup}
              name={nameOfItem}
              description={description}
            />
          )}
        </div>
      )}

{
  type === "found" && itemStatus === "settled" && (
    <FoundItems />
  )
}
    </div>
  );
}

export default SharedItem;

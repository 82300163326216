import React from "react";
import { FaTwitter, FaEnvelope } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  FooterItemsCon,
  FooterTitle,
  FooterItems,
  SocialMedia,
  SocialLogo,
  CopyRight,
  SocialIcons,
  SocialIconLink,
  SocialMediaWrap,
  LogoWrap,
} from "./FooterEls";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterItemsCon>
          <FooterTitle> Contact Us </FooterTitle>
          <FooterItems>
            <SocialMedia>
              <LogoWrap>
                <SocialLogo
                  style={{ textDecoration: "none" }}
                  to="/"
                  onClick={toggleHome}
                >
                  LOSTFINDER
                </SocialLogo>
              </LogoWrap>
              <SocialMediaWrap>
                <CopyRight>
                  lostfinder &copy; {new Date().getFullYear()}. All rights
                  reserved, by{" "}
                  <a href="https://github.com/Highshirt99">Devlolly</a>.
                </CopyRight>
                <SocialIcons>
                  <SocialIconLink
                    href={`mailto:${process.env.REACT_APP_CONTACT_US_EMAIL}`}
                    target="_blank"
                    aria-label="Email"
                    className="text-center"
                  >
                    <FaEnvelope />
                  </SocialIconLink>

                  <SocialIconLink
                    href="https://twitter.com/lostfinder17"
                    target="_blank"
                    aria-label="Twitter"
                    className="text-center"
                  >
                    <FaTwitter />
                  </SocialIconLink>
                </SocialIcons>
              </SocialMediaWrap>
            </SocialMedia>
          </FooterItems>
        </FooterItemsCon>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;


let broadcastItem = (item) => {
  let { _id, name, description, type } = item
  let title = type === 'lost' || type.type === 'lost' ? "Lost Item - Please Help" : "Looking for the Owner"
  let textForLostItems = `Please in case you help find ${name}, ${description}, you can return it via this Lostfinder link. Please help me share around! Thanks`
  let textForFoundItems = `If you are looking for your ${name}, ${description}, use this Lostfinder link to claim your item. Kindly share this to reach the owner quickly.`
  let text = type === 'lost' || type.type === 'lost' ? textForLostItems : textForFoundItems
  let url = `${window.location.origin}/item/${_id}`
  navigator.clipboard.writeText(`${text} \n\n ${url}`)

  try {
    return navigator.share({
      title,
      text,
      url,
    });
  } catch (error) {

  }
}

function isInViewport(el) {
  const rect = el.getBoundingClientRect();

  var isinview = (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)

  );

  return isinview;
}


module.exports = { broadcastItem, isInViewport }

import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages";
import SigninPage from "./pages/signin";
import Signup from "./pages/signup";
import ResetPasswordPage from "./pages/resetpassword";
import Homebody from "./components/homebody";
import Pass from "./pages/Pass";
import "./style.scss";
import Dashboard from "./pages/dashboard";

import SharedItem from "./pages/SharedItem";
import Terms from './pages/Terms';
import Faqs from './pages/Faqs';
import Feedback from "./pages/feedback";


function App() {
  return (
    <div>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/pass/:token" element={<Pass />} />
        <Route path="/home" element={<Homebody />} />
        <Route path="/item/:id" element={<SharedItem />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/feedback" element={<Feedback/>} />
      </Routes>
    </div>
  );
}

export default App;

import React from 'react'
import AuthHeader from '../components/AuthHeader';
import { FormObj1 } from './../components/infoSection/data';
import InfoForm from './../components/ItemInfoSec/index';


const ReturnForm = () => {

  return (
    
    <div>
      {/* <AuthHeader/> */}
<InfoForm {...FormObj1} />
</div>
  )
}

export default ReturnForm
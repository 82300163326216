import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { FaTimes } from "react-icons/fa";
import loader from "../../images/loader.gif";
import { useNavigate } from "react-router-dom";


const ClaimForm = (props) => {
  const user = Cookies.get("_udjwt");
  const [userToken, setUserToken] = useState(user);
  const [loading, setLoading] = useState(false);
  const itemId = props.id;
  const [location, setLocation] = useState(null);
  const [description, setDescription] = useState(null);
  const [date, setDate] = useState(null);
  const itemData = {
    location: location,
    description: description,
    when: date,
    id: itemId,
    token: userToken,
  };


  const navigate = useNavigate();

  useEffect(() => {
    let pageFrom = Cookies.get("pageFrom");
    if (pageFrom) {
      pageFrom = JSON.parse(pageFrom);
      setLocation(pageFrom.location);
      setDescription(pageFrom.description);
      setDate(pageFrom.date);
      Cookies.remove("pageFrom");

    }

    // else{
    //   setLocation(location);

    //   setDescription(props.description);
    //   setDate(date);
    // }
  }, []);


  const handleClaim = async (e) => {
    const apiURL = process.env.REACT_APP_API_URL;
    e.preventDefault();
    setUserToken(user);
    setLoading(true);
    if (userToken) {
      await axios.post(`${apiURL}/claimitem`, itemData).then((response) => {
        const result = response.data;
        alert(result.message);
        setLoading(false);
      });

      setLocation("");
      setDate("");
      setDescription("");
    } else if (!userToken) {
      // Save the item details (name, location, date, description)
      Cookies.set(
        "pageFrom",
        JSON.stringify({
          path: "/home",
          type: "claim",
          date,
          description,
          location,
          nameOfItem: props.name,
          itemId,
        })
      );

      // Redirect them to login
      setLoading(false)
      alert("Kindly login before you proceed.");
      navigate("/signin");
      window.location.reload(false);

     
    }
  };

  return (
    <div className="popup-box">
      <div className="claim">
        <div class="close" onClick={props.handleClose}>
          <FaTimes />
        </div>
        <p style={{ color: "#fff" }}>{props.name}</p>

        <h3>Claim This Item</h3>

        <form onSubmit={(e) => handleClaim(e) }>
          <label htmlFor="Description">Describe the item in details.</label>
          <input
            required
            type="text"
            placeholder="Describe to let the finder know you are the true owner."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <label htmlFor="Description" name="description">
            Locations
          </label>
          <input
            required
            type="text"
            name="location"
            placeholder="List all possible places you could have misplaced it."
            onChange={(e) => setLocation(e.target.value)}
            value={location}
          />
          <label htmlFor="Description">When last did you see the item?</label>
          <input
            required
            type="date"
            name="when"
            placeholder="Date and time"
            onChange={(e) => setDate(e.target.value)}
            value={date}
          />
          <button>
            {loading ? (
              <img src={loader} alt="" width={20} />
            ) : (
              "I am the Owner"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ClaimForm;

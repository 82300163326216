export const response = 
     [
        {
            "_id": "6087f023048ed72e8c8df8ee",
            "question": "What should I do with the item, no one is claiming it?",
            "answer": "Still keep it, we shall keep looking for the rightful owner.",
            "questionNumber": 7
        },
        {
            "_id": "6087f0a8048ed72e8c8df8f1",
            "question": "How much does the finder get?",
            "answer": "At LostFinder, as much as we would have loved to compensate finders, we do not do have the means presently. We hope buoyant owners of items found would appreciate the finders of their items. Although, we urge finders not to hold owners of items to ransom.",
            "questionNumber": 10
        },
        {
            "_id": "6087f1cf048ed72e8c8df8f5",
            "question": "My question is not here, how can I ask?",
            "answer": "Kindly message us through our email address in the contact section. Also feel free to call our phone number.",
            "questionNumber": 14
        },
        {
            "_id": "6087ef52048ed72e8c8df8eb",
            "question": "I met with an assumed owner but the item wasn’t his, what should I do?",
            "answer": "Ooops, do not release it to him. Register the details of the items again, the owner is coming it for it. Thanks.",
            "questionNumber": 4
        },
        {
            "_id": "6087f04c048ed72e8c8df8ef",
            "question": "Does LostFinders track missing items?",
            "answer": "No. We do not track missing items for now. We hope to provide such services in the future.",
            "questionNumber": 8
        },
        {
            "_id": "6087ef07048ed72e8c8df8ea",
            "question": "What if the finder denies my claim?",
            "answer": "This may be that you did not provide convincing details to ascertain you are the owner of the subject item. Kindly post another claim and provide more sprecific notable details about your items. You contact us for help.",
            "questionNumber": 3
        },
        {
            "_id": "6087f18e048ed72e8c8df8f4",
            "question": "Which countries does Lostfinder cover?",
            "answer": "Our platform is open to all netizens; around the world.",
            "questionNumber": 13
        },
        {
            "_id": "6087f080048ed72e8c8df8f0",
            "question": "What about the mobile app?",
            "answer": "The mobile app is not available yet.",
            "questionNumber": 9
        },
        {
            "_id": "6087ee113daeb76a3e638762",
            "question": "Is money involved?",
            "answer": "NO, money is not involved. The service is free of charge.",
            "questionNumber": 1
        },
        {
            "_id": "6087eed2048ed72e8c8df8e9",
            "question": "How do I get my items back?",
            "answer": "You will have to prove beyond doubt that you are the owner of the claimed item. If the finder accepts your claim, his phone number would be provided to you. You can then arrange how to meet to collect your items.",
            "questionNumber": 2
        },
        {
            "_id": "6087ef87048ed72e8c8df8ec",
            "question": "I met with a finder but the item isn’t mine, what should I do?",
            "answer": "Great! That’s good of you not collecting what is not yours. Register the details of your lost items again. The finder shall come through with it.",
            "questionNumber": 5
        },
        {
            "_id": "6087eff0048ed72e8c8df8ed",
            "question": "Why is my item not listed in the found items?",
            "answer": "This because the finder is yet to report it on the platform. Report the details of your lost item, the finder is coming soon!",
            "questionNumber": 6
        },
        {
            "_id": "6087f0fa048ed72e8c8df8f2",
            "question": "How does LostFinders make money?",
            "answer": "All our services are free of charge. Your donations shall keep this site up and running. Please contact us to donate.",
            "questionNumber": 11
        },
        {
            "_id": "6087f131048ed72e8c8df8f3",
            "question": "I have found an item, how do I deposit it to LostFinder?",
            "answer": "Keep the item with you, the owner will contact you. LostFinder does not collect items.",
            "questionNumber": 12
        }
    ]

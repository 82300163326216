import React, { useState, useEffect } from "react";
import axios from "axios";
import LostItemCard from "./lostItemCard";
import loader from "../../images/loader.gif";

let lostItemsObj;

const LostItems = () => {
  const [items, setItems] = useState("");
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    getAllItems();
  }, [items]);

  const apiURL = process.env.REACT_APP_API_URL;
  const availableLostItems = async () => {
    setItems(lostItemsObj);
    if (items) {
      setLoaded(true);
    }
  };
  const getAllItems = async () => {
    lostItemsObj
      ? availableLostItems()
      : await axios
          .get(`${apiURL}/items/lost`)
          .then((response) => {
            const allItems = response.data;
            lostItemsObj = allItems;
            setItems(allItems);
            //console.log(response.data)
            if (items) {
              setLoaded(true);
            }
          })
          .catch((error) => {
            setLoaded(false);
            //console.log(error)
          });
  };
  return loaded ? (
    <LostItemCard items={items} />
  ) : (
    <img
      src={loader}
      width="80px"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        bottom: "50%",
        right: "50%",
        transform: "translate(-50%, -50%",
      }}
    />
  );
};

export default LostItems;

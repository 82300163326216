import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { FaTimes } from "react-icons/fa";
import loader from "../../images/loader.gif";
import { useNavigate } from "react-router-dom";

const FindOwnerForm = (props) => {
  const user = Cookies.get("_udjwt");
  const [userToken, setUserToken] = useState(user);
  const itemId = props.id;
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const userData = {
    location: location,
    description: description,
    when: date,
    id: itemId,
    token: userToken,
  };

  const navigate = useNavigate();

  useEffect(() => {
    let pageFrom = Cookies.get("pageFrom");
    if (pageFrom) {
      pageFrom = JSON.parse(pageFrom);
      setLocation(pageFrom.location);

      setDescription(pageFrom.description);

      setDate(pageFrom.date);
      Cookies.remove("pageFrom");
    }
  }, []);

  const handleReturn = async (e) => {
    const apiURL = process.env.REACT_APP_API_URL;
    e.preventDefault();
    setUserToken(user);
    setLoading(true);

    if (userToken) {
      await axios.post(`${apiURL}/returnitem`, userData).then((response) => {
        const result = response.data;
        alert(result.message);
        //console.log(result, userData);
        setLocation("");
        setDate("");
        setDescription("");
        setLoading(false);
      });
    
    } else if (!userToken) {
      // Save the item details (name, location, date, description)
      Cookies.set(
        "pageFrom",
        JSON.stringify({
          path: "/home",
          type: "return",
          date,
          description,
          location,
          nameOfItem: props.name,
          itemId,
        })
      );

      // Redirect them to login
      setLoading(false);
      alert("Kindly login before you proceed.");
      navigate("/signin");
      window.location.reload(false);
    }
  };

  return (
    <div class="popup-box">
      <div className="claim">
        <div onClick={props.handleClose} class="close">
          <FaTimes />
        </div>
        <p style={{ color: "#fff" }}>{props.name}</p>

        <h3>Return This Item</h3>

        <form onSubmit={(e) => handleReturn(e)}>
          <label htmlFor="Description">Describe the item in details.</label>
          <input
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            placeholder="Describe to let the owner know it is their item."
            value={description}
            required
          />
          <label htmlFor="Description">Locations</label>
          <input
            onChange={(e) => setLocation(e.target.value)}
            type="text"
            placeholder="Where did you find the item?"
            value={location}
            required
          />
          <label htmlFor="Description">When did you find the item?</label>
          <input
            onChange={(e) => setDate(e.target.value)}
            type="date"
            placeholder="Date and time"
            value={date}
            required
          />

          <button>
            {loading ? (
              <img src={loader} alt="" width={20} />
            ) : (
              "Return to Owner"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FindOwnerForm;

/** @format */

import React from "react";
import { FaUser } from "react-icons/fa";
import Logout from "./Logout";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";

const AuthHeader = () => {
  const [profileIsOpen, setProfileIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const toggleProfileIsOpen = () => {
    setProfileIsOpen(!profileIsOpen);
  };
  const handleLogout = async () => {
    Cookies.remove("_udjwt");
    navigate("/signin");

  };

  let signedIn = Cookies.get("_udjwt");
  let currentPath = window.location.pathname;

  return (
    <div style={{ display: "flex" }} className="auth-header">
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <div
          onClick={toggleProfileIsOpen}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
            background: "#ffd300",
            padding: "12px",
            borderRadius: "50%",
            cursor: "pointer",
            marginLeft: "3rem",
          }}
          className="icon"
        >
          <FaUser />
        </div>
        <div className="header_options"><div
        style={{ marginLeft: "5px", marginTop: "10px", cursor: "pointer" }}
        onClick={handleLogout}
        >
        {profileIsOpen && signedIn && <Logout />}
        </div>
        <div
        style={{ marginLeft: "5px", marginTop: "10px", cursor: "pointer" }}
        >
        {profileIsOpen && currentPath === "/dashboard" && (
        <Link to={"/home"}>{"Home"}</Link>
        )}
        {profileIsOpen && currentPath !== "/dashboard" && (
        <Link to={signedIn ? "/dashboard" : "/signin"}>{"Dashboard"}</Link>
        )}
        </div></div>
      </div>
      <Link className="logo" to="/">
        LOSTFINDER
      </Link>
    </div>
  );
};

export default AuthHeader;

import React, {useState} from "react";
import { FaEnvelope } from "react-icons/fa";
import Navbar from "../components/navBar";
import Footer from "../components/Footer";
import SideBar from "../components/sideBar"

const Feedback = () => {
  const [isOpen, setIsOpen] = useState(false);


  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
       <SideBar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div style={{ textAlign: "center", marginTop: "15%", height: "50vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span
            style={{
              color: "white",
              background: "red",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "25px"
            }}
          >
            <FaEnvelope />
          </span>
        </div>
        <br />
        <p style={{fontSize: "1.5rem"}}>
          A mail has been sent to you. Kindly check it and click the link to set
          your password.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default Feedback;

import React, { useState, useEffect } from "react";
import HeroSection from "../components/heroSection";
import Navbar from "../components/navBar";
import SideBar from "../components/sideBar";
import InfoSection from "../components/infoSection";
import Services from "../components/servicesSection";
import { homeObjFour, homeObjOne } from "../components/infoSection/data";
import Footer from "../components/Footer";
import { callApiMetrics } from "../utils/metrics";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = async () => {
      await callApiMetrics({
        action: "page_load",
        email: null,
        origin: window.location.href,
      });
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />
      <HeroSection />

      <InfoSection {...homeObjOne} />
      <Services />
      <InfoSection {...homeObjFour} />

      <Footer />
    </>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FindForm from "../pages/findForm";
import ReturnForm from "../pages/returnForm";
import { useTheme } from "@material-ui/core";
import LostItems from "./detailsSec/lostItems";
import FoundItems from "./detailsSec/foundItems";
import Cookies from "js-cookie";
import "reactjs-popup/dist/index.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ minHeight: "100vh" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function FullWidthTabs() {
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [lostBtnActive, setLostBtnActive] = useState(false);
  const [foundBtnActive, setFoundBtnActive] = useState(false);
  const [btn3Active, setBtn3Active] = useState(false);

  const [showReturnForm, setshowReturnForm] = useState(false);
  const [showFindForm, setshowFindForm] = useState(false);
  const [itemsShown, setItemsShown] = useState(true);

  const toggleshowReturnForm = () => {
    setshowReturnForm(true);
    setshowFindForm(false);
    setBtn3Active(true);
    setLostBtnActive(false);
    setFoundBtnActive(false);
    setItemsShown(false);
  };
  const toggleshowFindForm = () => {
    setshowFindForm(true);
    setshowReturnForm(false);
    setBtn3Active(true);
    setLostBtnActive(false);
    setFoundBtnActive(false);
    setItemsShown(false);
  };

  const [lostBtnClicked, setLostBtnClicked] = useState(false);
  const toggleLostBtnClicked = () => {
    setLostBtnClicked(true);
    setFoundBtnClicked(false);
    setshowReturnForm(false);
    setshowFindForm(false);
    setLostBtnActive(true);
    setBtn3Active(false);
    setFoundBtnActive(false);
    setItemsShown(true);
  };

  const [foundBtnClicked, setFoundBtnClicked] = useState(false);
  const toggleFoundBtnClicked = () => {
    setFoundBtnClicked(true);
    setLostBtnClicked(false);
    setshowReturnForm(false);
    setshowFindForm(false);
    setFoundBtnActive(true);
    setLostBtnActive(false);
    setBtn3Active(false);
    setItemsShown(true);
  };

  const showLostItems = () => {
    setLostBtnClicked(true);
    setLostBtnActive(true);
  };
  const showFoundItems = () => {
    setFoundBtnClicked(true);
    setFoundBtnActive(true);
  };

  useEffect(() => {
    let pageFrom = Cookies.get("pageFrom");

    if (pageFrom) {
      pageFrom = JSON.parse(pageFrom);
      if (pageFrom?.type === "lost") {
        toggleshowFindForm();
      } else if (pageFrom?.type === "found") {
        toggleshowReturnForm();
      } else if (pageFrom?.type === "claim") {
        showFoundItems();
      }
      else {
        return showLostItems();
      }
    } else {
      return toggleshowFindForm();
    }
  }, []);

  let items;
  let button;
  if (lostBtnClicked) {
    items = <LostItems />;

    button = (
      <p
        onClick={toggleshowReturnForm}
        style={{
          backgroundColor: showReturnForm && "#fff",
          color: showReturnForm && "#010101",
          cursor: "pointer",
          padding: "6px",
          borderRadius: "50px",
          fontSize: ".8rem",
          position: "relative",
          top: "8px",
          textDecoration: "none",
        }}
      >
        Report Found Items
      </p>
    );
  } else if (foundBtnClicked) {
    items = <FoundItems />;

    button = (
      <p
        onClick={toggleshowFindForm}
        style={{
          cursor: "pointer",
          backgroundColor: showFindForm && "#fff",
          color: showFindForm && "#010101",
          padding: "6px",
          borderRadius: "50px",
          fontSize: ".8rem",
          position: "relative",
          top: "8px",
          textDecoration: "none",
        }}
      >
        Report Lost Items
      </p>
    );
  }

  return (
    <div
      style={{ backgroundColor: "#ffd300", marginTop: "-20px", zIndex: "1" }}
    >
      <TabPanel value={value} index={0} dir={theme.direction} style={{}}>
        <div className="report-button">
          <p
            onClick={toggleLostBtnClicked}
            id="lostBtn"
            style={{
              backgroundColor: lostBtnActive && "#fff",
              color: lostBtnActive && "#010101",
            }}
          >
            Lost Items
          </p>
          <p
            onClick={toggleFoundBtnClicked}
            id="foundBtn"
            style={{
              backgroundColor: foundBtnActive && "#fff",
              color: foundBtnActive && "#010101",
            }}
          >
            Found Items
          </p>
          {button}
        </div>

        {showReturnForm && <ReturnForm />}
        {showFindForm && <FindForm />}
        {itemsShown && items}
      </TabPanel >
    </div >
  );
}

import React from 'react'
import { FormObj2 } from './../components/infoSection/data';
import InfoForm from './../components/ItemInfoSec/index';
import AuthHeader from './../components/AuthHeader';


const FindForm = () => {

  return (
    
    
    <div>
      {/* <AuthHeader/> */}
<InfoForm {...FormObj2}/>
</div>
  )
}

export default FindForm
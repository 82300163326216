import React from "react";
import { useNavigate} from "react-router-dom";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap,
} from "./sideBarEls";

const SideBar = ({ isOpen, toggle }) => {
  let navigate = useNavigate();

  function handleClick(event) {
    event.preventDefault();
    navigate("/signup");
  }
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>

      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink style={{ textDecoration: "none" }} to="/home">Home</SidebarLink>
          <SidebarLink style={{ textDecoration: "none" }}  to="/terms">Terms</SidebarLink>
          <SidebarLink style={{ textDecoration: "none" }}  to="/faqs">FAQs</SidebarLink>
          <SidebarLink style={{ textDecoration: "none" }}  onClick={handleClick} to="signup">
            Sign Up
          </SidebarLink>
        </SidebarMenu>

        <SideBtnWrap>
          <SidebarRoute style={{ textDecoration: "none" }}  to="/signin">Sign In</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default SideBar;

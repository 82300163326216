import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../images/loader.gif";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import {
  Container,
  FormWrap,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  Text,
  LinkEl,
} from "./FormEls";

const Signin = ({
  formH1,
  formLabel1,
  formType1,
  formLabel2,
  formType2,
  for1,
  for2,
  buttonType,
  text,
  text2,
  span,
}) => {
  const [userData, setUserData] = useState({
    logEmail: "",
    logPassword: "",
  });

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const clone = { ...userData };
    clone[e.target.name] = e.target.value;
    setUserData(clone);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const apiURL = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.post(`${apiURL}/login`, userData);

      if (response.data.token) {
        swal("Done", "Login successful!!!", "success");
        Cookies.set("_udjwt", response.data.token, { expires: 30 });
        let pageFrom = Cookies.get("pageFrom");

        if (!pageFrom) {
          return navigate("/home");
        } else if (pageFrom) {
          pageFrom = JSON.parse(pageFrom);
          navigate(pageFrom.path);
        }
      } else {
        swal("Error", response.data.message, "error");
        e.target.logPassword.value = null;
        setLoading(false);
      }
    } catch (error) {}
  };

  return (
    <Container>
      <FormWrap>
        <Form action="#" onSubmit={(e) => handleSubmit(e)}>
          <FormH1>{formH1}</FormH1>
          <FormLabel htmlFor={for1}>{formLabel1}</FormLabel>
          <FormInput
            name="logEmail"
            onChange={(e) => handleChange(e)}
            type={formType1}
            required
          />
          <FormLabel htmlFor={for2}>{formLabel2}</FormLabel>
          <FormInput
            name="logPassword"
            onChange={(e) => handleChange(e)}
            type={formType2}
            required
          />
          <FormButton type={buttonType}>
            {loading ? <img src={loader} alt="" width={20} /> : "Sign In"}
          </FormButton>
          <Text>
            <LinkEl to="/resetpassword">{text}</LinkEl>
          </Text>
          <Text>
            {text2}
            <LinkEl to="/signup">{span}</LinkEl>
          </Text>
        </Form>
      </FormWrap>
    </Container>
  );
};

export default Signin;

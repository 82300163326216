/** @format */

import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Navbar from "../components/navBar";
import SideBar from "../components/sideBar";
import loader from "../images/loader.gif";
import errorImg from "../images/error.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Pass() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [userEmail, setUseremail] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [randomIdentifier, setRandomIdentifier] = useState("");
  const [showPassword, setShowpassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const toggleShowpassword = () => {
    setShowpassword(!showPassword);
  };

  const data = {
    userEmail: userEmail,
    password: password,
    randomIdentifier: randomIdentifier,
  };

  const apiURL = process.env.REACT_APP_API_URL;
  const { token } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const response = await axios.post(`${apiURL}/pass/setpassword`, data);

    const notify = () =>
      toast.success("Password set successfully, proceed to login.");
    notify();
    navigate("/signin");

    if (response) {
      setLoading(false);
    }
  };

  useEffect(() => {
    axios.get(`${apiURL}/pass/${token}`).then(
      (result) => {
        setIsLoaded(true);
        const username = result.data.message[0].firstName;
        const userLastname = result.data.message[0].lastName;
        const userMail = result.data.message[0].userEmail;
        const randomIdentifier = result.data.randomIdentifier;
        setFirstname(username);
        setLastname(userLastname);
        setUseremail(userMail);
        setRandomIdentifier(randomIdentifier);
        setStatus(result.data.status);
        setMessage(result.data.message);
      },

      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
  }, []);

  let content;
  if (error) {
    content = <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    content = (
      <div style={{ textAlign: "center", position: "relative", top: "200px" }}>
        <img alt="" src={loader} width={50} />
        <p className="verifying"> Verifying your details...</p>
      </div>
    );
  } else if (status === 404) {
    content = (
      <div className="expBox">
        <img src={errorImg} width={200} alt="Error" />
        <div>
          <p className="exp">{message}</p>
          <div className="btns">
            <Link to="/signup">
              <button>Sign Up</button>
            </Link>
            <Link to="/signin">
              <button>Sign In</button>
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div>
        <p>Kindly set your password below.</p>
        <form action="" onSubmit={(e) => handleSubmit(e)}>
          <label htmlFor="firstname">First Name</label>
          <input
            type="text"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstname(firstName)}
          />
          <label htmlFor="lastname">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastname(lastName)}
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="userEmail"
            value={userEmail}
            onChange={(e) => setUseremail(userEmail)}
          />
          <label htmlFor="password">Password</label>
          <div>
            <input
              type={showPassword ? "text" : "password"}
              name="userPassword"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {showPassword ? (
              <FaEye
                style={{
                  cursor: "pointer",
                  position: "relative",
                  right: "35px",
                }}
                onClick={toggleShowpassword}
              />
            ) : (
              <FaEyeSlash
                onClick={toggleShowpassword}
                style={{
                  cursor: "pointer",
                  position: "relative",
                  right: "35px",
                  alignItems: "center",
                }}
              />
            )}
          </div>

          <button>
            {loading ? <img src={loader} alt="" width={20} /> : "Submit"}
          </button>
        </form>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="welcome">
      <Navbar toggle={toggle} />
      <SideBar isOpen={isOpen} toggle={toggle} />
      <div>{content}</div>
    </div>
  );
}

export default Pass;

/** @format */

import React, { useState, useEffect } from "react";
import FindOwnerForm from "../claimItem/FindOwnerForm";
import { DetailsCon, H1, Card, CardItem, CardP, ButtonDiv } from "./detailsEls";
import { Button } from "../heroSection/buttonEls";
import { FaShare } from "react-icons/fa";
import { broadcastItem } from "../../utils/functions";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import Cookies from "js-cookie";
import "react-confirm-alert/src/react-confirm-alert.css";

const LostItemCard = (props) => {
  const [confirmMessage, setConfirmMessage] = useState(true);
  const [id, setId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [nameOfItem, setNameOfItem] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    let pageFrom = Cookies.get("pageFrom");
    if (pageFrom) {
      pageFrom = JSON.parse(pageFrom);
      setIsOpen(true);

      setNameOfItem(pageFrom.nameOReturn);
      setId(pageFrom.itemId);
      setDescription(pageFrom.description);
    }
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (e) => {
    setNameOfItem(e.name);
    setDescription(e.description);
    const itemId = e._id;
    togglePopup();
    setId(itemId);

    confirmAlert({
      title: "Confirm to submit",
      message:
        "Kindly note that you can only submit details on a particular item once, and you need to log in.",
      buttons: [
        {
          label: "Okay",
          onClick: () => setConfirmMessage(true),
        },

        {
          label: "Cancel",
          onClick: () => setConfirmMessage(false),
        },
      ],
    });

    if (confirmMessage) {
      setIsOpen(true);
      setConfirmMessage(false);
    }
  };

  const { items } = props;
  if (items.length > 0) {
    return (
      <DetailsCon id="detailsSection">
        <Card>
          {items.map((item, index) => {
            return (
              <CardItem key={item._id} style={{ textAlign: "left" }}>
                <Tooltip title="Broadcast">
                  <FaShare
                    onClick={() => broadcastItem(item)}
                    style={{
                      float: "right",
                      fontWeight: "20px",
                      marginTop: "20px",
                    }}
                  />
                </Tooltip>

                <H1>{item.name}</H1>
                <CardP style={{ textAlign: "left" }}>{item.description}</CardP>
                <ButtonDiv style={{ float: "left" }}>
                  <Button
                    to=""
                    style={{ color: "#fff", textDecoration: "none" }}
                    onClick={() => handleClick(item)}
                  >
                    I FOUND THIS
                  </Button>
                </ButtonDiv>
              </CardItem>
            );
          })}
        </Card>
        {isOpen && confirmMessage && (
          <FindOwnerForm handleClose={togglePopup} id={id} name={nameOfItem} />
        )}
      </DetailsCon>
    );
  } else {
    return <p className="text-center my-3">No item has been reported yet.</p>;
  }
};

export default LostItemCard;
